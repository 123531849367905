import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import "./CustomAgGridTable.scss";
import { useCallback, useEffect, useState,useRef } from "react";
import { assets, convertTZ } from "../../utils"
import { CustomButton } from "../../components/CustomButton"
import { useOrdersContext } from "../../contexts/OrdersContext"
import { useUserProfileContext } from "../../contexts/UserProfileContext"

export const CustomAgGridOrderTable = ({
  rowData,
  pagination,
  paginationPageSize,
  height,
  pageSize,
  onPageChange,
  currentPage,
  columnDefs,
  next,
  orders,
  totalPages
}) => {
  const navigate = useNavigate();
  const limit = 20;
  const [gridApi, setGridApi] = useState(null);
  const [dataStore, setDataStore] = useState(rowData);
  const centerColsViewportRef = useRef(null);
  const fakeScrollbarRef = useRef(null);
  const { userProfile, getLoginUserProfile } = useUserProfileContext()
  const user = JSON.parse(localStorage.getItem("user"))
  const timezone = user.user_profile.timezone

  const { isLoading, getOrders, syncOrders, downloadInvoice } =
    useOrdersContext()

  const onRowClicked = row => {
    const { is_payment_profile_exists } = userProfile
    if (!is_payment_profile_exists) {
      return navigate(`/payment`)
    }

    navigate(`/orders/${row.id}`)
  }
  return (
    <div className="ag-theme-alpine" style={{ height: height || "40vh", width: "100%" }}>
      <div style={{height: "100%"}}>
          <div
            class="ag-root-wrapper ag-ltr ag-layout-normal"
            role="presentation"
            grid-id="6"
          >
            <div
              class="ag-root-wrapper-body ag-focus-managed ag-layout-normal"
              role="presentation"
            >
              <div class="ag-tab-guard ag-tab-guard-top" role="presentation"></div>
                <div
                  class="ag-root ag-unselectable ag-layout-normal"
                  role="treegrid"
                  aria-colcount="6"
                  aria-rowcount="1"
                >
                    <div
                        className="ag-header ag-pivot-off ag-header-allow-overflow"
                        role="presentation"
                        style={{ height: "49px", minHeight: "49px" }}
                      >
                        <div class="ag-header-viewport " role="presentation">
                            <div class="ag-header-container" role="rowgroup" style={{width:'1464px'}}>
                                <div class="ag-header-row ag-header-row-column" role="row" aria-rowindex="1" style={{
                                    transform: "translateZ(0px)",
                                    height: "48px",
                                    top: "0px",
                                    width: "1449px",
                                  }}>
                                    <div
                                      className="ag-header-cell ag-focus-managed"
                                      col-id="name"
                                      role="columnheader"
                                      tabIndex="-1"
                                      aria-colindex="1"
                                      style={{ width: "242px", top: "0px", height: "48px", left: "0px" }}
                                    >
                                      <div
                                        className="ag-header-cell-resize ag-hidden"
                                        role="presentation"
                                        aria-hidden="true"
                                      ></div>
                                      <div className="ag-header-cell-comp-wrapper" role="presentation">
                                        <div className="ag-cell-label-container" role="presentation">
                                          <div
                                            
                                            className="ag-header-cell-label"
                                            role="presentation"
                                          >
                                            <span
                                              
                                              className="ag-header-cell-text"
                                            >
                                              Order No.
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="ag-header-cell ag-focus-managed"
                                      col-id="order_created_at"
                                      role="columnheader"
                                      tabIndex="-1"
                                      aria-colindex="2"
                                      style={{ width: '242px', top: '0px', height: '48px', left: '242px' }}
                                    >
                                      <div className="ag-header-cell-resize ag-hidden" role="presentation" aria-hidden="true"></div>
                                      <div className="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden" aria-hidden="true">
                                        <div className="ag-input-field-label ag-label ag-hidden ag-checkbox-label" aria-hidden="true" role="presentation"></div>
                                        <div className="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" role="presentation">
                                          <input
                                            className="ag-input-field-input ag-checkbox-input"
                                            type="checkbox"
                                            id="ag-33-input"
                                            tabIndex="-1"
                                            aria-label="Press Space to toggle all rows selection (unchecked)"
                                          />
                                        </div>
                                      </div>
                                      <div className="ag-header-cell-comp-wrapper" role="presentation">
                                        <div className="ag-cell-label-container" role="presentation">
                                          <div className="ag-header-cell-label" role="presentation">
                                            <span className="ag-header-cell-text">Order Date</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="ag-header-cell ag-focus-managed"
                                      col-id="customer_name"
                                      role="columnheader"
                                      tabIndex="-1"
                                      aria-colindex="3"
                                      style={{ width: '242px', top: '0px', height: '48px', left: '484px' }}
                                    >
                                      <div className="ag-header-cell-resize ag-hidden" role="presentation" aria-hidden="true"></div>
                                      <div className="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden" aria-hidden="true">
                                        <div className="ag-input-field-label ag-label ag-hidden ag-checkbox-label" aria-hidden="true" role="presentation"></div>
                                        <div className="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" role="presentation">
                                          <input
                                            className="ag-input-field-input ag-checkbox-input"
                                            type="checkbox"
                                            id="ag-108-input"
                                            tabIndex="-1"
                                            aria-label="Press Space to toggle all rows selection (unchecked)"
                                          />
                                        </div>
                                      </div>
                                      <div className="ag-header-cell-comp-wrapper" role="presentation">
                                        <div className="ag-cell-label-container" role="presentation">
                                          <div className="ag-header-cell-label" role="presentation">
                                            <span className="ag-header-cell-text">Customer</span>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                        className="ag-header-cell ag-focus-managed"
                                        col-id="total_cost_price"
                                        role="columnheader"
                                        tabIndex="-1"
                                        aria-colindex="4"
                                        style={{ width: '242px', top: '0px', height: '48px', left: '726px' }}
                                      >
                                        <div className="ag-header-cell-resize ag-hidden" role="presentation" aria-hidden="true"></div>
                                        <div
                                          role="presentation"
                                          className="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                                          aria-hidden="true"
                                        >
                                          <div className="ag-input-field-label ag-label ag-hidden ag-checkbox-label" aria-hidden="true" role="presentation"></div>
                                          <div className="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" role="presentation">
                                            <input
                                              className="ag-input-field-input ag-checkbox-input"
                                              type="checkbox"
                                              id="ag-109-input"
                                              tabIndex="-1"
                                              aria-label="Press Space to toggle all rows selection (unchecked)"
                                            />
                                          </div>
                                        </div>
                                        <div className="ag-header-cell-comp-wrapper" role="presentation">
                                          <div className="ag-cell-label-container" role="presentation">
                                            <div className="ag-header-cell-label" role="presentation">
                                              <span className="ag-header-cell-text">Total Value</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                          className="ag-header-cell ag-focus-managed"
                                          col-id="common_status_descr"
                                          role="columnheader"
                                          tabIndex="-1"
                                          aria-colindex="5"
                                          style={{ width: '242px', top: '0px', height: '48px', left: '968px' }}
                                        >
                                          <div className="ag-header-cell-resize ag-hidden" role="presentation" aria-hidden="true"></div>
                                          <div
                                            role="presentation"
                                            className="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                                            aria-hidden="true"
                                          >
                                            <div className="ag-input-field-label ag-label ag-hidden ag-checkbox-label" aria-hidden="true" role="presentation"></div>
                                            <div className="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" role="presentation">
                                              <input
                                                className="ag-input-field-input ag-checkbox-input"
                                                type="checkbox"
                                                id="ag-110-input"
                                                tabIndex="-1"
                                                aria-label="Press Space to toggle all rows selection (unchecked)"
                                              />
                                            </div>
                                          </div>
                                          <div className="ag-header-cell-comp-wrapper" role="presentation">
                                            <div className="ag-cell-label-container" role="presentation">
                                              <div className="ag-header-cell-label" role="presentation">
                                                <span className="ag-header-cell-text">Status</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="ag-header-cell ag-focus-managed"
                                          col-id="action"
                                          role="columnheader"
                                          tabIndex="-1"
                                          aria-colindex="6"
                                          style={{ width: '239px', top: '0px', height: '48px', left: '1210px' }}
                                        >
                                          <div className="ag-header-cell-resize ag-hidden" role="presentation" aria-hidden="true"></div>
                                          <div
                                            role="presentation"
                                            className="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                                            aria-hidden="true"
                                          >
                                            <div className="ag-input-field-label ag-label ag-hidden ag-checkbox-label" aria-hidden="true" role="presentation"></div>
                                            <div className="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" role="presentation">
                                              <input
                                                className="ag-input-field-input ag-checkbox-input"
                                                type="checkbox"
                                                id="ag-111-input"
                                                tabIndex="-1"
                                                aria-label="Press Space to toggle all rows selection (unchecked)"
                                              />
                                            </div>
                                          </div>
                                          <div className="ag-header-cell-comp-wrapper" role="presentation">
                                            <div className="ag-cell-label-container" role="presentation">
                                              <div className="ag-header-cell-label" role="presentation">
                                                <span className="ag-header-cell-text">Actions</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="ag-body ag-layout-normal" role="presentation">
                      <div className="ag-body-viewport ag-row-no-animation ag-layout-normal ag-has-focus" role="presentation" style={{ width: 'calc(100% + 0px)' }}>
                        <div className="ag-pinned-left-cols-container ag-hidden" role="presentation" aria-hidden="true" style={{ height: '84px', width: '0px', maxWidth: '0px', minWidth: '0px' }}></div>
                        <div className="ag-center-cols-viewport" role="presentation" style={{ height: '584px' }} ref={centerColsViewportRef}>
                            <div className="ag-center-cols-container" role="rowgroup" style={{ width: '1464px', height: '84px' }}>

                          {rowData.map((row, index) => (
                              <div onClick={
                                e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  onRowClicked(row)
                                }
                              }  role="row" row-index={index} row-id={index} className="ag-row-even ag-row ag-row-level-0 ag-row-position-absolute ag-row-first ag-row-not-inline-editing ag-row-focus" aria-rowindex={index + 1} style={{ transform: `translateY(${index * 42}px)`, height: '42px' }}>
                                <div tabIndex="-1" role="gridcell" col-id="name" className="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-cell-value" aria-colindex="1" style={{ left: '0px', width: '244px' }}>{
                                  row?.name  
                                }</div>
                                <div tabIndex="-1" role="gridcell" col-id="order_created_at" className="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-cell-value ag-cell-focus" aria-colindex="2" style={{ left: '244px', width: '244px' }}><span>{
                                  convertTZ(row?.order_created_at, timezone)
                              }</span></div>
                                <div tabIndex="-1" role="gridcell" col-id="customer_name" className="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-cell-value" aria-colindex="3" style={{ left: '488px', width: '244px' }}>{
                                  row?.customer_name  
                                }</div>
                                <div tabIndex="-1" role="gridcell" col-id="total_cost_price" className="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-cell-value" aria-colindex="4" style={{ left: '732px', width: '244px' }}><span>$ {Number(row?.total_cost_price).toFixed(2)}</span></div>
                                <div tabIndex="-1" role="gridcell" col-id="common_status_descr" className="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-cell-value" aria-colindex="5" style={{ left: '976px', width: '244px' }}><span className="Returned">{
                                  row?.common_status_descr
}</span></div>
                                <div tabIndex="-1" role="gridcell" col-id="action" className="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-cell-value" aria-colindex="6" style={{ left: '1220px', width: '244px' }}>
                                  
                                  {row.common_status === "U" ? <CustomButton label="Pay" variant="primary" className="ms-auto" /> : row.common_status === "C" ? null : <CustomButton
                                    label="Download Invoice"
                                    variant="outline-primary"
                                    className="ms-auto"
                                    type="button"
                                    onClick={e => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      downloadInvoice(row.id)
                                    }}
                                  />}


                                </div>
                              </div>
                            ))}



                            
                          </div>
                        </div>
                        <div className="ag-pinned-right-cols-container ag-hidden" role="presentation" aria-hidden="true" style={{ height: '84px', width: '0px', maxWidth: '0px', minWidth: '0px' }}></div>
                        <div className="ag-full-width-container" role="presentation" style={{ height: '84px' }}></div>
                      </div>
                      

                    </div>
                </div>
            </div>

            
            <div class="ag-paging-panel ag-unselectable" id="ag-40">
              <span class="ag-paging-row-summary-panel" role="status">
                <span
                  id="ag-40-first-row"
                  
                  class="ag-paging-row-summary-panel-number"
                  >{ (currentPage - 1) * 20 == 0 ? 1 : (currentPage - 1) * 20 + 1} </span>
                  <span id="ag-40-to">to </span>
                  <span
                  id="ag-40-last-row"
                  
                  class="ag-paging-row-summary-panel-number"
                  >{currentPage * 20} </span>
                <span id="ag-40-of">of </span>
                <span
                  id="ag-40-row-count"
                  
                  class="ag-paging-row-summary-panel-number"
                  >{pageSize}</span>
              </span>
              <span
                class="ag-paging-page-summary-panel"
                role="presentation"
              >
                <div
                  
                  class="ag-button ag-paging-button ag-disabled"
                  role="button"
                  aria-label="First Page"
                  tabindex="0"
                  aria-disabled="true"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    onPageChange(1)
                  }}
                  style={{cursor: 'pointer'}}
                >
                  <span
                    class="ag-icon ag-icon-first"
                    unselectable="on"
                    role="presentation"
                  ></span>
                </div>
                <div
                  
                  class="ag-button ag-paging-button ag-disabled"
                  role="button"
                  aria-label="Previous Page"
                  tabindex="0"
                  aria-disabled="true"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (currentPage === 1) {
                      return
                    }
                    onPageChange(currentPage - 1)
                  }}
                  style={{cursor: 'pointer'}}
                >
                  <span
                    class="ag-icon ag-icon-previous"
                    unselectable="on"
                    role="presentation"
                  ></span>
                </div>
                <span class="ag-paging-description" role="status">
                  <span id="ag-40-start-page">Page </span>
                  <span
                    id="ag-40-start-page-number"
                    
                    class="ag-paging-number"
                    >{rowData.length > 0 ? orders.current_page : 0 } </span>
                  <span id="ag-40-of-page">of </span>
                  <span
                    id="ag-40-of-page-number"
                    
                    class="ag-paging-number"
                    >{rowData.length > 0 ? orders.total_pages : 0 } </span>
                </span>
                <div
                  
                  class="ag-button ag-paging-button ag-disabled"
                  role="button"
                  aria-label="Next Page"
                  tabindex="0"
                  aria-disabled="true"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    
                    if (currentPage === totalPages) {
                      return
                    } 
                    onPageChange(currentPage + 1)
                  }}
                  style={{cursor: 'pointer'}}
                >
                  <span
                    class="ag-icon ag-icon-next"
                    unselectable="on"
                    role="presentation"
                  ></span>
                </div>
                <div
                  
                  class="ag-button ag-paging-button ag-disabled"
                  role="button"
                  aria-label="Last Page"
                  tabindex="0"
                  aria-disabled="true"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    onPageChange(totalPages)
                  }}
                  style={{cursor: 'pointer'}}
                >
                  <span
                    class="ag-icon ag-icon-last"
                    unselectable="on"
                    role="presentation"
                  ></span>
                </div>
              </span>
            </div>
          </div>

          
      </div>
    </div>
  );
};
